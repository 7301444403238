require('./bootstrap');
require('jquery');
require('sweetalert2');
import * as JQuery from "jquery";
import swal from 'sweetalert2';
import PureCounter from "@srexi/purecounterjs";
import Atropos from "atropos";
import Swiper from 'swiper';
import GLightbox from 'glightbox';
import LazyLoad from "vanilla-lazyload";
import Hashes from "jshashes";
import QRCode from "easyqrcodejs";

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import * as FilePond from 'filepond';


window.$ = JQuery.default;
window.Swal = swal
window.PureCounter = PureCounter;
window.Atropos = Atropos;
window.Swiper = Swiper;
window.GLightbox = GLightbox;
window.LazyLoad = LazyLoad;
window.FilePond = FilePond;
window.FilePondPluginImagePreview = FilePondPluginImagePreview;
window.FilePondPluginImageExifOrientation = FilePondPluginImageExifOrientation;
window.FilePondPluginFileValidateSize = FilePondPluginFileValidateSize;
window.FilePondPluginImageEdit = FilePondPluginImageEdit;
window.FilePondPluginFileEncode = FilePondPluginFileEncode;
window.Hashes = Hashes;
window.QRCode = QRCode;
// 464
